import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Btn from "../components/button"

const NotFoundPage = ({location}) => (
  <Layout>
    <Seo 
      title="showcase21 |  Hier ist nichts"
      description="Hier ist nichts. Diese Seite existiert nicht."
      lang="de"
      pathname={location.pathname}
      robots="noindex,follow"
    />
    <Section>
      <div className="row">
        <div className="col-md-8">
          <h1 className="small">404 | Ups hier ist nichts!</h1>
          <h2 className="main-heading">Wer hat sich denn da verklickt?</h2>
          <p className="subheading align-left">Komm gerne zurück zur Startseite und entdecke mehr von showcase21.</p>
        </div>
      </div>
      <div className="row">
        <div className="col d-flex flex-column flex-md-row justify-content-around justify-content-md-start">
          <Btn
            type="primary"
            text="Zur Startseite"
            href="/" 
          />
        </div>
      </div>
    </Section>

  </Layout>
)

export default NotFoundPage
